import './Home.css';

const Home = () => {
    return(
        <div className="home-page-container">
            <h1 className="home-page-title">Student Management System</h1>
        </div>
    );
}

export default Home;